import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { MobileComponent } from "./MobileComponent";
import { Header } from "./components/Header";
import { StatsHome } from "./components/stats/StatsHome";
import { LiveMatchHome } from "./components/LiveMatch/LiveMatchHome";
import { Stats } from "./components/Stats";
import { LiveMatch } from "./components/LiveMatch";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import HelpView from "./components/HelpView";
import JoinUsView from "./components/JoinUsView";   
import GuideView from "./components/GuideView";
import BlogComponent from "./components/blog/BlogComponent";
import ShopComponent from "./components/shop/ShopComponent";
import ShopDetailsComponent from "./components/shop/ShopDetailsComponent";
import { PaypalConfirmationView } from "./components/shop/PaypalConfirmationView";
import Cart from "./components/shop/CartComponent";
import LoginView from "./components/login/LoginComponent";
import { auth, analytics } from "./firebase";
import BlogDetailsComponent from "./components/blog/BlogDetailsComponent";
import ProfileView from "./components/login/ProfileComponent";
import { Redirect } from 'react-router-dom';
import CalendarView from "./components/calendar/CalendarView";
import ReservationView  from "./components/reservation/ReservationView";
import ReservationDayView  from "./components/reservation/ReservationDayView";
import ReservationClubView  from "./components/reservation/ReservationClubView";
import BigScreenView from "./components/bigscreen/BigScreenView";
// import { auth } from './firebase'; // Adjust the path as necessary

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = auth.currentUser; // Check if a user is logged in

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};


const App = () => {
  const { t, i18n } = useTranslation();

  analytics.setAnalyticsCollectionEnabled(true);

  if (window.firebase && window.firebase.analytics) {
    analytics.setAnalyticsCollectionEnabled(true);
    window.firebase.analytics().setAnalyticsCollectionEnabled(true);
    window.firebase.analytics().debugModeEnabled = true;
  }
  
  useEffect(() => {
    if (analytics) {
      analytics.setAnalyticsCollectionEnabled(true);
      analytics.logEvent("page_view", { page_path: window.location.pathname });
    }
  }, [analytics]);

  return (
    <section>
      <Header translation={{ t, i18n }} analytics={analytics} />
      <div>
        <Switch>
          <Route exact path="/" render={(props) => <MobileComponent {...props} analytics={analytics} translation={{ t, i18n }} />} />
          <Route path="/mobile" render={(routeProps) => <MobileComponent {...routeProps} analytics={analytics} />} />
          
          {/* Use PrivateRoute for stats */}
          <PrivateRoute exact path="/stats" component={StatsHome} />
          <PrivateRoute exact path="/stats/:id" component={Stats} />

          <PrivateRoute exact path="/live" component={LiveMatchHome} />
          <Route exact path="/live/:id" render={(routeProps) => <LiveMatch analytics={analytics} translation={{ t, i18n }} />} />
          <Route path="/help" render={(routeProps) => <HelpView translation={{ t, i18n }} />} />
          <Route path="/joinus" render={(routeProps) => <JoinUsView translation={{ t, i18n }} />} />
          <Route path="/guideview" render={(routeProps) => <GuideView translation={{ t, i18n }} />} />
          <Route path="/blog" render={(routeProps) => <BlogComponent translation={{ t, i18n }} />} />
          <Route exact path="/blogdetail/:id" render={(routeProps) => <BlogDetailsComponent translation={{ t, i18n }} analytics={analytics} />} />
          <Route path="/shop" render={(routeProps) => <ShopComponent analytics={analytics} translation={{ t, i18n }} />} />
          <Route path="/cart" render={(routeProps) => <Cart analytics={analytics} translation={{ t, i18n }} />} />
          <Route exact path="/shopdetail/:id" render={(routeProps) => <ShopDetailsComponent translation={{ t, i18n }} analytics={analytics} />} />
          <Route exact path="/paypalsuccess/:id" render={(props) => <PaypalConfirmationView analytics={analytics} />} />
          <Route exact path="/login" render={(props) => <LoginView analytics={analytics} />} />
          <Route exact path="/profile" render={(props) => <ProfileView analytics={analytics} translation={{ t, i18n }} />} />
          <PrivateRoute exact path="/calendar" component={CalendarView} />
          <Route path="/reservations/:club/:day" render={(props) => <ReservationView analytics={analytics} translation={{ t, i18n }} />} />
          <Route path="/days/:club" render={(props) => <ReservationDayView {...props} analytics={analytics} translation={{ t, i18n }} />} />
          <Route path="/reservation" render={(props) => <ReservationClubView {...props} analytics={analytics} translation={{ t, i18n }} />} />
          <Route exact path="/bigscreen" component={BigScreenView} />
        </Switch>
      </div>
    </section>
  );
};

export default App;
