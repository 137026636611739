import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useHistory } from 'react-router-dom';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const ReservationDayView = ({ match }) => {
  const { club } = match.params;
  const history = useHistory(); // Initialize useHistory

  return (
    <div className="p-5">
      <div className='flex flex-row'>
        <button onClick={() => history.goBack()} className="mb-4 bg-gray-500 text-white p-2 rounded"><i className='fa fa-arrow-left'/></button> {/* Back Button */}
      </div>
      <div className='flex flex-row'>
        <h2 className="text-2xl font-bold mb-4">{club}</h2>
      </div>
      <div className='flex flex-wrap justify-center items-center h-[20px]'>
      {daysOfWeek.map((day) => (
        <Link key={day} to={`/reservations/${club}/${day}`} className="m-2">
          <div className="w-32 h-32 bg-gray-200 flex items-center justify-center rounded shadow-lg">
            <span className="text-lg font-semibold">{day}</span>
          </div>
        </Link>
      ))}
    </div>
    </div>
  );
};


export default ReservationDayView