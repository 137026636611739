import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export const Header = ({ translation, analytics }) => {
  const [slideMenuVisible, setSlideMenuVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisibleLanguage, setDropdownVisibleLanguage] = useState(false);
  const [dropdownVisibleHelp, setDropdownVisibleHelp] = useState(false);
  const dropdownRef = useRef(null);
  const { t, i18n } = translation;
  const history = useHistory(); // Use useHistory
  const cart = useSelector((state) => state.cart.cart);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownVisible(false);
    setDropdownVisibleLanguage(false);
    analytics.logEvent("react_change_language_header", { event: "clicked" });
  };

  const goToLogin = () => {
    setDropdownVisibleHelp(false);
    history.push("/login");
  };

  const handleLogout = () => {
    localStorage.removeItem("email");
    setTimeout(() => {
      history.push("/");
      window.location.reload();
    }, 1000);
  };

  const isLoggedIn = () => {
    return localStorage.getItem("email") !== null;
  };

  const cartItemCount = cart.length; // Example count of items in the cart

  return (
    <header>
      <div className="bg-header-500">
        <div className="flex lg:flex lg:justify-evenly">
          <div className="flex-grow lg:flex-grow">
            <Link to="/">
              <img
                width={120}
                height={120}
                src="https://messonstats.com:8443/images/logomessonstats_green.png"
                alt="Tennis Raquet"
              />
            </Link>
          </div>
          <div>
            <div className="flex-grow-0 lg:flex-grow-0 m-10">
              <div className="hidden lg:flex lg:flex-row gap-10 lg:text-2xl">
                <div className="flex flex-row">
                  <div className="content-center">
                    <img
                      width={30}
                      height={30}
                      src="https://messonstats.com:8443/images/tennis_ball.PNG"
                      alt="Tennis Ball"
                    />
                  </div>
                  <div>
                    <Link
                      to="/"
                      className="block px-4 py-2 font-header text-white hover:bg-header-500"
                    >
                      {t("home")}
                    </Link>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="content-center">
                    <img
                      width={30}
                      height={30}
                      src="https://messonstats.com:8443/images/tennis_ball.PNG"
                      alt="Tennis Ball"
                    />
                  </div>
                  <div>
                    <Link
                      to="/shop"
                      className="block px-4 py-2 font-header text-white hover:bg-header-500"
                    >
                      {t("shop")}
                    </Link>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="content-center">
                    <img
                      width={30}
                      height={30}
                      src="https://messonstats.com:8443/images/tennis_ball.PNG"
                      alt="Tennis Ball"
                    />
                  </div>
                  <div>
                    <Link
                      to="/live"
                      className="block px-4 py-2 font-header text-white hover:bg-header-500"
                    >
                      {t("live")}
                    </Link>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="content-center">
                    <img
                      width={30}
                      height={30}
                      src="https://messonstats.com:8443/images/tennis_ball.PNG"
                      alt="Tennis Ball"
                    />
                  </div>
                  <div>
                    <div
                      className="relative block px-4 py-2 font-header text-white hover:bg-header-500"
                      ref={dropdownRef}
                    >
                      <button
                        className="font-bold"
                        onClick={() =>
                          setDropdownVisibleHelp(!dropdownVisibleHelp)
                        }
                      >
                        <i className="fa fa-bars text-2xl" />
                      </button>
                      {dropdownVisibleHelp && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
                          <div>
                            {isLoggedIn() ? (
                              <div>
                                <Link
                                  className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                                  to="/profile"
                                >
                                  {t("profile")}
                                </Link>
                              </div>
                            ) : (
                              <button
                                className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                                onClick={goToLogin}
                              >
                                Login
                              </button>
                            )}
                          </div>
                          <div>
                            <Link
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              to="/stats"
                            >
                              {t("stats")}
                            </Link>
                          </div>
                          <div>
                            <Link
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              to="/blog"
                            >
                              {t("blog")}
                            </Link>
                          </div>
                          <div>
                            <Link
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              to="/calendar"
                            >
                              {t("calendar")}
                            </Link>
                          </div>
                          <div>
                            <Link
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              to="/reservation"
                            >
                              {t("reservation")}
                            </Link>
                          </div>
                          <div>
                            <Link
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              to="/help"
                            >
                              {t("help")}
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div>
                    <div
                      className="relative block px-4 py-2 font-header text-white hover:bg-header-500"
                      ref={dropdownRef}
                    >
                      <Link className="relative" to="/cart">
                        <i className="fa fa-shopping-cart text-2xl" />
                        <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                          {cartItemCount}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center lg:hidden">
            <div>
              <div
                className="relative block px-4 py-2 font-header text-white hover:bg-header-500"
                ref={dropdownRef}
              >
                <Link className="relative" to="/cart">
                  <i className="fa fa-shopping-cart text-2xl" />
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                    {cartItemCount}
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex-grow lg:flex-grow">
            <div className="text-end m-10 lg:hidden">
              <div className="relative" ref={dropdownRef}>
                <button
                  className="font-bold"
                  onClick={() => setSlideMenuVisible(!slideMenuVisible)}
                >
                  <i className="fa fa-bars text-2xl" />
                </button>

                {slideMenuVisible && (
                  <div className="fixed top-0 left-0 w-3/4 h-full bg-white shadow-lg z-50">
                    <div className="flex justify-end p-4">
                      <button
                        onClick={() => setSlideMenuVisible(false)}
                        className="text-xl"
                      >
                        <i className="fa fa-times" />
                      </button>
                    </div>
                    <div className="flex flex-col items-center">
                      <Link
                        to="/"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("home")}
                      </Link>
                      <Link
                        to="/shop"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("shop")}
                      </Link>
                      <Link
                        to="/live"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("live")}
                      </Link>
                      <Link
                        to="/stats"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("stats")}
                      </Link>
                      <Link
                        to="/blog"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("blog")}
                      </Link>
                      <Link
                        to="/calendar"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("calendar")}
                      </Link>
                      <Link
                        to="/reservation"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("reservation")}
                      </Link>
                      <Link
                        to="/help"
                        className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                        onClick={() => setSlideMenuVisible(false)}
                      >
                        {t("help")}
                      </Link>
                      {isLoggedIn() ? (
                        <Link
                          className="block px-16 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                          to="/profile"
                        >
                          {t("profile")}
                        </Link>
                      ) : (
                        <button
                          className="block px-4 py-2 text-gray-800 text-2xl hover:bg-gray-200"
                          onClick={goToLogin}
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
