import React, { useEffect, useState } from "react";
import { StatsCard } from "../stats/StatsCard";
import { API_BASE_URL } from "../../network/API_BASE_URL";

const BigScreenView = () => {
    const [matchesData, setMatchesData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showResults, setShowResults] = useState(true);
    const itemsPerRow = 3;
    const itemsPerSlide = itemsPerRow * 3; // 3 rows of 3 items
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch matches data from backend
    useEffect(() => {
        const fetchMatchesData = async () => {
            try {
                const response = await fetch( API_BASE_URL+"/product"); // Adjust the URL as necessary
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setMatchesData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchMatchesData();
    }, []);

    // Prepare matches for display after data is fetched
    const getCurrentMatches = () => {
        if (loading || error) return [];

        const liveMatches = matchesData.filter((match) => match.score === " : ");
        const finishedMatches = matchesData.filter((match) => match.score !== " : ");

        const lowScoringMatches = finishedMatches.filter(match => {
            const scoreParts = match.score?.split(':');
            if (!scoreParts || scoreParts.length < 2) return false;

            const [score1, score2] = scoreParts.map(Number);
            return score1 < 5 || score2 < 5;
        });

        const otherFinishedMatches = finishedMatches.filter(match => {
            const scoreParts = match.score?.split(':');
            if (!scoreParts || scoreParts.length < 2) return false;

            const [score1, score2] = scoreParts.map(Number);
            return score1 >= 5 && score2 >= 5;
        });

        return showResults ? [...lowScoringMatches, ...otherFinishedMatches] : liveMatches;
    };

    const currentMatches = getCurrentMatches();

    // Automatically slide to the next match
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => {
                const maxIndex = Math.ceil(currentMatches.length / itemsPerSlide) - 1;
                return prevIndex < maxIndex ? prevIndex + 1 : 0;
            });
        }, 5000);

        return () => clearInterval(interval);
    }, [showResults, currentMatches.length, itemsPerSlide]);

    const handleNextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const maxIndex = Math.ceil(currentMatches.length / itemsPerSlide) - 1;
            return prevIndex < maxIndex ? prevIndex + 1 : 0;
        });
    };

    const handlePrevSlide = () => {
        setCurrentIndex((prevIndex) => {
            const maxIndex = Math.ceil(currentMatches.length / itemsPerSlide) - 1;
            return prevIndex > 0 ? prevIndex - 1 : maxIndex;
        });
    };

    // Get the matches to display in the current slide
    const currentDisplayMatches = currentMatches.slice(currentIndex * itemsPerSlide, (currentIndex + 1) * itemsPerSlide);

    return (
        <div className="m-5">
            <h1 className="text-2xl font-bold mb-4">
                {showResults ? "Resultados" : "En Vivo"}
            </h1>
            <div className="overflow-hidden">
                <div className="grid grid-cols-3 gap-2">
                    {currentDisplayMatches.map((item, index) => (
                        <div className="p-2" key={index}>
                            <StatsCard
                                player={item.company} 
                                player2={item.company2}
                                score={item.score}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <button onClick={handlePrevSlide}>Previous</button>
            <button onClick={handleNextSlide}>Next</button>
        </div>
    );
};

export default BigScreenView;
