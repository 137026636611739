import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API_BASE_URL } from "../../network/API_BASE_URL";

const courts = ["Court 1", "Court 2", "Court 3", "Court 4"];
const times = Array.from({ length: 18 }, (_, i) => `${i + 5}:00`);

const ReservationView = ({analytics, translation}) => {
  const { t } = translation;
  const { club, day } = useParams();
  const history = useHistory();
  const [reservations, setReservations] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newReservation, setNewReservation] = useState({
    title: "",
    time: "",
    court: "",
  });

  useEffect(() => {
    const sendEmail = async () => {
      const email = localStorage.getItem("email");
      try {
        const response = await fetch(`${API_BASE_URL}/reservations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        if (!response.ok) {
          throw new Error("Failed to send email");
        }

        const result = await response.json();
        setReservations(result);
      } catch (error) {
        console.error("Error sending email:", error);
      }
    };

    sendEmail();
  }, []); 

  const addReservation = async () => {

    try {
      if (
        !newReservation.title ||
        !newReservation.time ||
        !newReservation.court
      ) {
        alert("All fields are required!");
        return;
      }

      const email = localStorage.getItem("email");
      const username = localStorage.getItem("username");

      const reservationWithId = { ...newReservation, club, day, id: Date.now() };

      const response = await fetch(`${API_BASE_URL}/createreservation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reservationWithId),
      });

      if (!response.ok) {
        throw new Error("Failed to create event");
      }

      setReservations([...reservations, reservationWithId]);
      setNewReservation({ title: "", time: "", court: "" });
      setModalOpen(false);
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const deleteReservation = async (id) => {

    try {
        const response = await fetch(`${API_BASE_URL}/removereservation`, {
          method: 'POST', // Change to POST
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id }), // Send the event ID in the request body
        });
    
        if (!response.ok) {
          throw new Error('Failed to delete event');
        }

        setReservations(reservations.filter((res) => res.id !== id));

      } catch (error) {
        console.error('Error deleting event:', error);
      }

  };

  return (
    <div className="p-5">
      <button
        onClick={() => history.goBack()}
        className="mb-4 bg-gray-500 text-white p-2 rounded"
      >
        <i className="fa fa-arrow-left" />
      </button>{" "}
      {/* Back Button */}
      <h2 className="text-xl font-bold mb-4">{day} Reservations</h2>
      <button
        onClick={() => setModalOpen(true)}
        className="mb-4 bg-blue-500 text-white p-2 rounded"
      >
        {t("reservation_view_button_first")}
      </button>
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2">Time</th>
            {courts.map((court) => (
              <th key={court} className="border border-gray-300 p-2">
                {court}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {times.map((time) => (
            <tr key={time}>
              <td className="border border-gray-300 p-2">{time}</td>
              {courts.map((court) => (
                <td key={court} className="border border-gray-300 p-2">
                  {reservations
                    .filter((res) => res.time === time && res.court === court)
                    .map((res) => (
                      <div key={res.id} className="relative inline-block">
                        <span className="inline-block bg-green-300 text-green-800 rounded-full px-2 py-1 text-xs font-semibold mr-1">
                          {res.title}
                        </span>
                        <button
                          onClick={() => deleteReservation(res.id)}
                          className="absolute -right-1 -top-1 text-red-500"
                        >
                          x
                        </button>
                      </div>
                    ))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-bold">{t("reservation_view_button_first")}</h3>
            <input
              type="text"
              placeholder={t("reservation_view_label_first")}
              value={newReservation.title}
              onChange={(e) =>
                setNewReservation({ ...newReservation, title: e.target.value })
              }
              className="border border-gray-300 p-2 mb-2 w-full"
            />
            <select
              value={newReservation.time}
              onChange={(e) =>
                setNewReservation({ ...newReservation, time: e.target.value })
              }
              className="border border-gray-300 p-2 mb-2 w-full"
            >
              <option value="">{t("reservation_view_label_second")}</option>
              {times.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
            <select
              value={newReservation.court}
              onChange={(e) =>
                setNewReservation({ ...newReservation, court: e.target.value })
              }
              className="border border-gray-300 p-2 mb-2 w-full"
            >
              <option value="">{t("reservation_view_label_third")}</option>
              {courts.map((court) => (
                <option key={court} value={court}>
                  {court}
                </option>
              ))}
            </select>
            <button
              onClick={addReservation}
              className="bg-blue-500 text-white p-2 rounded"
            >
              {t("reservation_view_label_fourth")}
            </button>
            <button
              onClick={() => setModalOpen(false)}
              className="ml-2 bg-gray-500 text-white p-2 rounded"
            >
              {t("reservation_view_label_fifth")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReservationView;
