import React from "react";
import { motion } from "framer-motion";

export const MobileRightSideComponent = () => {
  const slideInVariants = {
    hidden: { x: "100%", opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <motion.div
      className="w-[100%] lg:w-[100%] lg:h-[15vh] bg-header-500 content-center inline-block"
      initial="hidden"
      animate="visible"
      variants={slideInVariants}
      transition={{ type: "spring", stiffness: 50, damping: 20 }}
    >
      <div>
        <img
          className="m-10"
          width={500}
          src={
            "https://messonstats.com:8443/images/tennis_raquet_and_ball.jpg"
          }
          alt="tennis raquet"
        />
      </div>
    </motion.div>
  );
};
