import React from "react";
import { Link, useHistory } from "react-router-dom";

const ProfileView = ({ analytics, translation }) => {
  const username = localStorage.getItem("username") || "Usuario";

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formattedUsername = capitalizeWords(username);
  const [language, setLanguage] = React.useState("en");
  const history = useHistory();
  const { t, i18n } = translation;

  const handleLogout = () => {
    localStorage.removeItem("email");
    setTimeout(() => {
      history.push("/");
      window.location.reload();
    }, 1000);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng); // Update the language state
    analytics.logEvent("react_change_language_profile", { event: "clicked" });
  };

  return (
    <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
      {/* Profile Image */}
      <img
        src="https://messonstats.com:8443/images/avatar.png"
        alt="Profile"
        className="rounded-full w-32 h-32 mb-4 shadow-lg"
      />
      {/* Username */}
      <h1 className="text-2xl font-semibold text-gray-800">{formattedUsername}</h1>

      {/* Banner */}
      <div className="mt-2 bg-blue-100 p-4 rounded-lg shadow-md">
        <p className="text-center text-gray-600">
          Siguiendo las estadisticas desde el 2023, Agradecemos la preferencia!
        </p>
      </div>

      {/* Language Dropdown */}
      <div className="mt-4 w-full max-w-xs">
        <label className="block text-gray-700 mb-2" htmlFor="language">
          Selecciona el idioma:
        </label>
        <select
          id="language"
          value={language}
          onChange={(e) => changeLanguage(e.target.value)} // Call changeLanguage function
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="en">English</option>
          <option value="es">Español</option>
        </select>
      </div>

      {/* Logout Button */}
      <button
  onClick={handleLogout}
  className="mt-6 w-full max-w-xs px-6 py-3 bg-red-600 text-white rounded hover:bg-red-500"
>
  Cerrar Sesión
</button>

    </div>
  );
};

export default ProfileView;
