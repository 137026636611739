import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProductData } from "../redux/actions/productActions";
import { event } from "../firebaseEvents";
import { API_BASE_URL } from "../network/API_BASE_URL";

const logos = [ 
  "rolling_steaks.JPG",
  "kunos.JPG",
  "empower.JPG",
  "pinasupply_new.JPG",
  "ovalsilhouette_new_2.JPG",
  "koi_new.JPG",
  "carlos_saint_new.JPG",
  "elite_broker_new.JPG",
  "carlos_felipe_new.JPG",
  "geronimogomez_new.JPG",
  "guzmanariza_new.JPG",
  "la5tamendoza_new.JPG",
  "simmons_new.JPG",
  "pro_pasajero_new.JPG", 
  "esimflys_new.JPG",
  "puntomac_new.JPG",
];

var scoreDashData = "0";
var scoreDashData2 = "0";
var gameRecord = 0;
var gameRecord2 = 0;
var changeServiceBall = false;
let scoreNote = ["0", "15", "30", "40", "Adv"];

const carouselItems = [
  {
    title: "live_modal_page_label_fifth",
    description: "live_modal_page_label_second_slider_nineth",
    image: "https://messonstats.com:8443/images/oferta_rolling_steaks.JPG",
    storename: "Rolling Steaks ",
    telephone: "Siguenos en @rolling.rd",
    url: "https://www.instagram.com/rolling.rd/"
  },
  {
    title: "live_modal_page_label_fifth",
    description: "live_modal_page_label_second_slider_nineth",
    image: "https://messonstats.com:8443/images/oferta_kunos.JPG",
    storename: "Kuno's",
    telephone: "809-519-1904",
    url: "https://www.instagram.com/kunos.do/"
  },
  {
    title: "live_modal_page_label_first",
    description: "live_modal_page_label_second_slider_fourth",
    image: "https://messonstats.com:8443/images/oferta_pina_supply_2.JPG",
    storename: "Pina Supply ",
    telephone: "809-554-9923",
    url: "https://www.instagram.com/pinasupply/"
  },
  {
    title: "live_modal_page_label_first",
    description: "live_modal_page_label_second_slider_sixth",
    image: "https://messonstats.com:8443/images/oferta_esimflys.JPG",
    storename: "eSimflys ",
    telephone: "809-883-2849",
    url: "https://www.instagram.com/esimflys/"
  },
  {
    title: "live_modal_page_label_first",
    description: "live_modal_page_label_second_slider_second",
    image: "https://messonstats.com:8443/images/oferta_koi.JPG",
    storename: "KOI Fusion ",
    telephone: "809-683-6524",
    url: "https://www.instagram.com/koisushi_rd/"
  },
  {
    title: "live_modal_page_label_fourth",
    description: "live_modal_page_label_second_slider_third",
    image: "https://messonstats.com:8443/images/oferta_puntomac.JPG",
    storename: "Punto Mac ",
    telephone: "809-412-0806",
    url: "https://www.instagram.com/puntomac/"
  },
  {
    title: "live_modal_page_label_first",
    description: "live_modal_page_label_second",
    image: "https://messonstats.com:8443/images/oferta_babolat_pure_aero.JPG",
    storename: "Match Point ",
    telephone: "809-732-3846",
    url: "https://www.instagram.com/matchpointstorerd/?hl=es"
  }
  // Add more items as needed
];

const Modal = ({ isOpen, onClose, translation, analytics }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!isOpen) return null;
  const { t } = translation;

  const openNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleOpenNewTab = (value) => {
    // Ensure `event` and `event.liveAdsMoreInfo` are defined
    if (event && event.liveAdsMoreInfo) {
      let event_item = {
        "user": localStorage.getItem("username"),
        "view": event.view ? event.view.liveAdsMoreInfo : null
      }
      analytics.logEvent(event.liveAdsMoreInfo, event_item);
    } else {
      console.warn("Event or liveAdsMoreInfo is not defined");
    }
    openNewTab(value);
  };
  

  const handleNext = () => {
    analytics.logEvent("on_ver_oferta_next", { event: "clicked" });
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    analytics.logEvent("on_ver_oferta_prev", { event: "clicked" });
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1
    );
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderMediaContent = () => {
    const { image } = carouselItems[currentIndex];
    const isVideo = image.endsWith(".mp4");
    const isImage = image.endsWith(".JPG");

    if (isVideo) {
      return (
        <video
          src={image}
          controls
          className="mx-auto my-4"
          style={{ width: "100%", height: "450px" }}
        />
      );
    }

    if (isImage) {
      return (
        <img
          src={image}
          alt={`Carousel Item ${currentIndex + 1}`}
          className="mx-auto my-4"
          style={{ width: "100%", height: "450px" }}
        />
      );
    }

    return null;
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOverlayClick}
    >
      <div
        className="bg-white rounded shadow-lg"
        style={{
          width: "325px",
          height: "750px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="flex-grow p-4">
          <h2 className="text-xl font-bold mb-4">
            {t(carouselItems[currentIndex].title)}
          </h2>
          <p>{t(carouselItems[currentIndex].description)}</p>
          {renderMediaContent()}
          <div className="m-1">
            <p className="font-bold">{carouselItems[currentIndex].storename}</p>
            <p>{carouselItems[currentIndex].telephone}</p>
            <button
              onClick={() => handleOpenNewTab(carouselItems[currentIndex].url)}
              className="underline text-blue-700 cursor-pointer"
            >
              {t("live_modal_page_label_third")}
            </button>
          </div>
        </div>
        <div className="flex justify-between m-4">
          <button
            onClick={handlePrev}
            className="px-4 py-2 bg-gray-300 rounded"
          >
            <div className="font-bold text-2xl">{t("live_modal_page_button_second")}</div>
          </button>
          <button
            onClick={handleNext}
            className="px-4 py-2 bg-gray-300 rounded"
          >
            <div className="font-bold text-2xl">{t("live_modal_page_button_third")}</div>
          </button>
        </div>
      </div>
    </div>
  );
};


export const LiveMatch = ({ translation, analytics }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = translation;

  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal
  const product = useSelector((state) => state.product.product);

  const filteredData = product.find(
    (data) => data.id.toLowerCase() === id.toLowerCase()
  );

  const player = filteredData ? filteredData.company : "";
  const player2 = filteredData ? filteredData.company2 : "";
  const actions = filteredData ? filteredData.actions : "";

  let scoreNewP1 = null;
  let scoreNewP2 = null;

  if (filteredData && Array.isArray(filteredData.score) && filteredData.score.length >= 3) {
    scoreNewP1 = filteredData.score[0];
    scoreNewP2 = filteredData.score[2];
  }

  scoreNewP1 = filteredData.score[0];
  scoreNewP2 = filteredData.score[2];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % logos.length);
      dispatch(fetchProductData());
    }, 5000);
    scoreDashData = "0";
    scoreDashData2 = "0";
    gameRecord = 0;
    gameRecord2 = 0;

    analytics.logEvent(player + "_VS_" + player2, { event: "clicked" });

    return () => clearInterval(interval);
  }, [dispatch]);

  procesarDatos(actions, player, player2);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    analytics.logEvent("ver_oferta", { event: "clicked" });
  };

  // const handleModalOpen = () => setIsModalOpen(true); // Open modal
  const handleModalClose = () => setIsModalOpen(false); // Close modal

  return (
    <section>
      <div className="lg:w-full">
        <div className="lg:flex lg:flex-col">
          <div className="hidden lg:flex lg:flex-row lg:ml-10">
            <div className="bg-white">
              <div className="flex items-center justify-center  h-[100px] self-center">
                {logos.map((logo, index) => (
                  <img
                    style={{
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    width={80}
                    height={80}
                    key={index}
                    src={API_BASE_URL+"/images/" + logo}
                    alt={`Logo ${index}`}
                    className={`absolute transition-opacity duration-500 ${
                      index === activeIndex ? "opacity-100" : "opacity-0"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-col lg:flex-rows-3 lg:gap-10 lg:ml-10">
            <div className="lg:w-[100%]" />
            <div className="lg:flex lg:flex-rows-3 lg:gap-10 lg:ml-10">
              <div className="lg:w-[30%]" />
              <div className="lg:flex lg:flex-col">
                <div className="lg:w-[300px] lg:h-[50px] lg:text-center lg:content-center lg:bg-blue-500 lg:shadow lg:m-1 lg:rounded">
                  <h1 className="lg:text-xl lg:font-body lg:font-bold text-white">
                    {player}
                  </h1>
                </div>
                <div className="lg:w-[300px] lg:h-[50px] lg:text-center lg:content-center lg:bg-blue-500 lg:shadow lg:m-1 lg:rounded">
                  <h1 className="lg:text-xl lg:font-body lg:font-bold text-white">
                    {player2}
                  </h1>
                </div>
              </div>
              <div className="lg:flex lg:flex-col">
                <div className="lg:w-[100px] lg:h-[50px] lg:text-center lg:content-center lg:bg-blue-500 lg:shadow lg:m-1 lg:rounded">
                  <h1 className="lg:text-2xl lg:font-body lg:font-bold text-white">
                    {scoreNewP1}
                  </h1>
                </div>
                <div className="lg:w-[100px] lg:h-[50px] lg:text-center lg:content-center lg:bg-blue-500 lg:shadow lg:m-1 lg:rounded">
                  <h1 className="lg:text-2xl lg:font-body lg:font-bold text-white">
                    {scoreNewP2}
                  </h1>
                </div>
              </div>
            </div>
            <div className="lg:flex lg:flex-rows-3 lg:gap-10 lg:ml-10">
              <div className="lg:w-[30%]" />
              <div className="lg:flex lg:flex-col">
                <div className="lg:w-[200px] lg:h-[100px] lg:text-center lg:content-center lg:bg-blue-600 lg:shadow lg:m-1">
                  <h1 className="lg:text-5xl lg:font-body lg:font-bold">
                    {gameRecord == "7" && gameRecord2 == "7"
                      ? scoreDashData
                      : scoreNote[scoreDashData]}
                  </h1>
                </div>
                <div className="lg:w-[200px] lg:h-[30px] lg:text-center lg:bg-blue-600 lg:shadow lg:m-1">
                  <h1>{player}</h1>
                </div>
              </div>
              <div className="lg:flex lg:flex-col">
                <div className="lg:w-[200px] lg:h-[100px] lg:text-center lg:content-center lg:bg-blue-600 lg:shadow lg:m-1">
                  <h1 className="lg:text-5xl lg:font-body lg:font-bold">
                    {gameRecord == "7" && gameRecord2 == "7"
                      ? scoreDashData2
                      : scoreNote[scoreDashData2]}
                  </h1>
                </div>
                <div className="lg:w-[200px] lg:h-[30px] lg:text-center lg:bg-blue-600 lg:shadow lg:m-1">
                  <h1>{player2}</h1>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile Version */}
          <div className="lg:hidden flex flex-row ml-1">
            <div className="bg-white">
              <div className="flex items-center justify-center h-[100px] self-center">
                {logos.map((logo, index) => (
                  <img
                    style={{
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    width={80}
                    height={80}
                    key={index}
                    src={API_BASE_URL+"/images/" + logo}
                    alt={`Logo ${index}`}
                    className={`absolute transition-opacity duration-500 ${
                      index === activeIndex ? "opacity-100" : "opacity-0"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-col flex-rows-3 gap-10 ml-5">
            <div className="w-[100%]" />
            <div className="flex flex-rows-3 ">
              <div className="w-[10%]" />
              <div className="flex flex-col">
                <div className="w-[200px] h-[50px] text-center content-center bg-blue-500 shadow m-1 rounded">
                  <h1 className="text-xl  text-white">{player}</h1>
                </div>
                <div className="w-[200px] h-[50px] text-center content-center bg-blue-500 shadow m-1 rounded">
                  <h1 className="text-xl  text-white">{player2}</h1>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="w-[100px] h-[50px] text-center content-center bg-blue-500 shadow m-1 rounded">
                  <h1 className="text-2xl font-body font-bold text-white">
                    {scoreNewP1}
                  </h1>
                </div>
                <div className="w-[100px] h-[50px] text-center content-center bg-blue-500 shadow m-1 rounded">
                  <h1 className="text-2xl font-body font-bold text-white">
                    {scoreNewP2}
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="w-[190px] h-[100px] text-center content-center bg-blue-600 shadow m-1">
                  <h1 className="text-5xl font-body font-bold">
                    {gameRecord == "7" && gameRecord2 == "7"
                      ? scoreDashData
                      : scoreNote[scoreDashData]}
                  </h1>
                </div>
                <div className="w-[190px] h-[30px] text-center bg-blue-600 shadow m-1">
                  <h1>{player}</h1>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="w-[190px] h-[100px] text-center content-center bg-blue-600 shadow m-1">
                  <h1 className="text-5xl font-body font-bold">
                    {gameRecord == "7" && gameRecord2 == "7"
                      ? scoreDashData2
                      : scoreNote[scoreDashData2]}
                  </h1>
                </div>
                <div className="w-[190px] h-[30px] text-center bg-blue-600 shadow m-1">
                  <h1>{player2}</h1>
                </div>
              </div>
            </div>
            <div className="lg:hidden flex flex-row items-center justify-center h-[100px]">
              <div className="text-center">
                <div className="flex flex-row">
                <div className="text-red-600">New:&nbsp;</div>
                <h1
                  onClick={handleModalOpen}
                  className="underline text-blue-700 cursor-pointer"
                >
                  {t("live_view_page_button_first")}
                </h1>

                </div>
              </div>
            </div>
            <Modal
              isOpen={isModalOpen}
              onClose={handleModalClose}
              translation={translation}
              analytics={analytics}
            />{" "}
            {/* Modal component */}
          </div>
        </div>
      </div>
    </section>
  );
};

const procesarDatos = (datos, company, company2) => {
  let marcador = { "Eric Daneri": 0, "Martin Genao": 0 };
  let gameTable = { "Eric Daneri": 0, "Martin Genao": 0 };
  marcador[company] = 0;
  marcador[company2] = 0;
  gameTable[company] = 0;
  gameTable[company2] = 0;
  let juegoActual = 0;
  for (const accion of datos) {
    const jugador = accion.player;
    const juego = accion.game;

    // Verificar si el juego ha cambiado
    if (juego !== juegoActual) {
      juegoActual = juego;
    }

    switch (accion.action) {
      case "WINNER":
        marcador[jugador]++;
        break;
      case "ACE":
        marcador[jugador]++;
        break;
      case "DOUBLE_FAULT":
        if (accion.player === company2) {
          marcador[jugador === company ? company2 : company]++;
          break;
        } else {
          marcador[jugador === company2 ? company : company2]++;
          break;
        }
      case "FORCED_ERROR":
        if (accion.player === company2) {
          marcador[jugador === company ? company2 : company]++;
          break;
        } else {
          marcador[jugador === company2 ? company : company2]++;
          break;
        }
      case "UNFORCED_ERROR":
        if (accion.player === company2) {
          marcador[jugador === company ? company2 : company]++;
          break;
        } else {
          marcador[jugador === company2 ? company : company2]++;
          break;
        }
      case "RETURN_ERROR":
        if (accion.player === company2) {
          marcador[jugador === company ? company2 : company]++;
          break;
        } else {
          marcador[jugador === company2 ? company : company2]++;
          break;
        }
      default:
        break;
    }

    if (marcador[company] > 3 && marcador[company2] > 3) {
      marcador[company] = 3;
      marcador[company2] = 3;
    }

    // Mostrar el marcador y la acción
    // console.log(`${jugador} - ${marcador[company]}-${marcador[company2]} - ${accion.action} (Game ${juegoActual})`);

    scoreDashData = marcador[company];
    scoreDashData2 = marcador[company2];

    if (
      (marcador[company] >= 4 || marcador[company2] >= 4) &&
      Math.abs(marcador[company] - marcador[company2]) >= 2
    ) {
      changeServiceBall = !changeServiceBall;
      if (marcador[company] > marcador[company2]) {
        gameTable[company]++;
      } else {
        gameTable[company2]++;
      }
      marcador[company] = 0;
      marcador[company2] = 0;
      scoreDashData = 0;
      scoreDashData2 = 0;
      gameRecord = gameTable[company];
      gameRecord2 = gameTable[company2];
    }
  }
};
