import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../network/API_BASE_URL';

const times = Array.from({ length: 18 }, (_, i) => `${5 + i}:00`);
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const Modal = ({ event, onClose, onSave, onDelete }) => {
  const [title, setTitle] = useState(event?.title || '');
  const [day, setDay] = useState(event?.day || 'Monday');
  const [time, setTime] = useState(event?.time || '8:00');

  const handleSave = () => {
    const newEvent = {
      id: event?.id || Date.now(),
      title,
      day,
      time,
    };
    onSave(newEvent);
  }; 

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-lg font-bold mb-4">{event?.id ? 'Update Event' : 'Add Event'}</h2>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Event Title"
          className="border mb-4 p-2 w-full"
        />
        <select value={day} onChange={(e) => setDay(e.target.value)} className="border mb-4 p-2 w-full">
          {days.map(d => (
            <option key={d} value={d}>{d}</option>
          ))}
        </select>
        <select value={time} onChange={(e) => setTime(e.target.value)} className="border mb-4 p-2 w-full">
          {times.map(t => (
            <option key={t} value={t}>{t}</option>
          ))}
        </select>
        <div className="flex justify-end">
          <button onClick={handleSave} className="mr-2 bg-blue-500 text-white p-2">
            {event?.id ? 'Update' : 'Add'}
          </button>
          {onDelete && (
            <button onClick={() => onDelete(event.id)} className="mr-2 bg-red-500 text-white p-2">
              Delete
            </button>
          )}
          <button onClick={onClose} className="bg-gray-300 p-2">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const CalendarView = () => {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust breakpoint as needed
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const sendEmail = async () => {
      const email = localStorage.getItem("email");
  
      if (!email) {
        console.error('No email found in local storage');
        return;
      }
  
      try {
        const response = await fetch(`${API_BASE_URL}/events`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to send email');
        }
  
        const result = await response.json();
        setEvents(result);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
  
    sendEmail();
  }, []); // Send email on mount
  

  const openModal = (event) => {
    setCurrentEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentEvent(null);
    setIsModalOpen(false);
  };

  const handleAddEvent = async (newEvent) => {
    try {
      const email = localStorage.getItem("email");
      
      // Include the email in the newEvent object
      const eventWithEmail = { ...newEvent, email };
  
      const response = await fetch(`${API_BASE_URL}/createvent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventWithEmail),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create event');
      }
  
      const savedEvent = await response.json();
      setEvents([...events, savedEvent]);
      closeModal();
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };
  

  const handleUpdateEvent = async (updatedEvent) => { 
    try {

      const response = await fetch(`${API_BASE_URL}/updatevent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEvent),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update event');
      }

      // Update the state with the newly updated event
      setEvents(prevEvents => {
        // Create a new array with the updated event
        const updatedEvents = prevEvents.map(event => 
          event.id === updatedEvent.id ? updatedEvent : event
        );
  
        // Sort events by start time (assuming `startTime` is the relevant property)
        return updatedEvents.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
      });
  
      // Close the modal after the event is successfully updated
      closeModal();
      
    } catch (error) {
      console.error('Error updating event:', error);
      // Optionally, handle the error (e.g., show a notification)
    }
  };
  
  const handleDeleteEvent = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/deletevent`, {
        method: 'POST', // Change to POST
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }), // Send the event ID in the request body
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete event');
      }
  
      setEvents(events.filter(event => event.id !== id));
      closeModal();
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };  

  return (
    <div className="flex flex-col p-4">
      <button className="m-4 bg-blue-500 text-white p-2" onClick={() => openModal({})}>
        Add Event
      </button>
      <div className="grid grid-cols-8 gap-2">
        <div className="font-bold">Time</div>
        {days.map(day => (
          <div key={day} className="font-bold">{isMobile ? day.substring(0, 3) : day}</div>
        ))}
        {times.map(time => (
          <React.Fragment key={time}>
            <div>{time}</div>
            {days.map((day, dayIndex) => (
              <div
                key={dayIndex}
                className="border border-gray-300 h-14 relative cursor-pointer"
                onClick={() => {
                  const clickedEvent = events.find(event => event.time === time && event.day === day);
                  if (clickedEvent) {
                    openModal(clickedEvent);
                  }
                }}
              >
                {events.filter(event => event.time === time && event.day === day).map(event => (
                  <div key={event.id} className="bg-blue-500 text-white p-1">{event.title}</div>
                ))}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>      
      {isModalOpen && (
        <Modal
          event={currentEvent}
          onClose={closeModal}
          onSave={currentEvent?.id ? handleUpdateEvent : handleAddEvent}
          onDelete={currentEvent?.id ? handleDeleteEvent : null}
        />
      )}
    </div>
  );

};

export default CalendarView;
