import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useHistory } from 'react-router-dom';
import { API_BASE_URL } from '../../network/API_BASE_URL';

const clubs = [{
    "id": "club_arroyo_hondo",
    "name": "Club Arroyo Hondo",    
    "image": "club_arroyo_hondo.PNG",
    "address":"Dr. Jose Antonio Polanco Billini 10, Santo Domingo"
  },{
    "id": "club_naco",
    "name": "Club Naco",
    "image": "club_naco.PNG",
    "address":"C. Salvador Sturla, Santo Domingo"
  },{
    "id": "club_banreservas",
    "name": "Club BanReservas",
    "image": "club_banreservas.PNG",
    "address":"Av. Luperón Prox. Av. Cayetano Germosen, Santo Domingo"
  },{
    "id": "club_gazcue",
    "name": "Club Gazcue",
    "image": "club_gazcue.PNG",
    "address":"C. Ramón Santana 1a, Santo Domingo, Santo Domingo"
  }]

const ReservationClubView = ({analytics,translation}) => {
  const history = useHistory();
  const { t } = translation 
    return (
      <div className="m-5">
        <div className=''>
          <button onClick={() => history.goBack()} className="mb-4 bg-gray-500 text-white p-2 rounded"><i className='fa fa-arrow-left'/></button> {/* Back Button */}
        </div>
        <div className='flex flex-col items-center justify-center h-[20]'>
        <h1 className="text-3xl font-bold mb-4">{t("reservation_view_label_sixth")}</h1>
        {clubs.map((club) => (
          <Link
            key={club}
            to={`/days/${club.id}`}
            className="m-10"
            >
            <img
                  src={API_BASE_URL+"/images/"+club.image}
                  alt={club.name}
                  style={{ width: "800px", height: "250px" }}
                  className="object-cover"
                />
            <div className='font-bold'>
              {club.name}
            </div>
            <div>
              {club.address}
            </div>
          </Link>
        ))}
      </div>
      </div>
    );
  };

  export default ReservationClubView